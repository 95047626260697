import React from 'react';
import { Grid, Typography } from '@mui/material';
import PaperGridItem from '../components/PaperGridItem';
import SEO from '../components/SEO';
import MainBanner from '../components/MainBanner';

const Home = () => {
    return (
        <>
            <SEO
                title='Welcome to multiplyr.net'
                description='A collection of free calculators and widgets to help with everyday life'
                url='https://www.multiplyr.net'
                keywords='mortgage repayment calculator, annual, monthly, interest, interest rate, mortgage, repayment, mortgage repayments, repayments, calculator, mortgage monthly, monthly mortgage repayment, mortgage rate change, mortgage rate, mortgage rate calculator'
                name='multiplyr.net'
                type='website' />
                
            <MainBanner
                title="Welcome to multiplyr.net"
                subtitle="A repository of free calculators and widgets to help with everyday life" />

            <div className="grid-container">

                <Grid container spacing={2} sx={{ paddingLeft:'5%', paddingRight:'5%', paddingTop: 2 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} sx={{ paddingTop: 2 }}>
                        <PaperGridItem
                            title='Mortgage Repayment'
                            subtitle='Calculate your monthly mortgage payment along with a year-by-year breakdown of interest and balance payments'
                            link='/mortgage-repayment-calculator' />
                    </Grid>

                </Grid>
            </div>
        </>
    );
}
export default Home;