import React from 'react';
import { useState } from 'react';
import { Button } from '@material-ui/core';
import { Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { MortgageTable } from './MortgageTable';
import { IMortgageYearDetails } from '../../models/mortgageModels/IMortgageYearDetails';
import { IMortgageParams } from '../../models/mortgageModels/IMortgageParams';
import * as CommonFunctions from '../../functions/Common';
import {
    getMortgagePayment,
    getYearlyMortgageDetails,
} from "../../functions/Mortgage";

export const MortgageResults = ( { currencySymbolCode, balanceRemaining, propertyValue, interestRate, mortgageTerm } : IMortgageParams) => {
    const [openShareDialog, setOpenShareDialog] = useState(false);

    const handleOpenShareDialog = () => {
        setOpenShareDialog(true);
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false);
    };

    const currencySymbol = CommonFunctions.getCurrencySymbolByCode(currencySymbolCode) ?? '';

    var tableData = {} as IMortgageYearDetails;
    var totalInterestPayable = 0 as number | undefined;
    var monthlyPayment = 0 as number;
    var loanToValue = 0 as number;
    var shareLink = '';

    if (propertyValue != null && +propertyValue > 0
        && balanceRemaining != null && +balanceRemaining > 0
        && interestRate != null && +interestRate > 0
        && mortgageTerm != null && mortgageTerm > 0)
    {
        tableData.yearlyDetails = getYearlyMortgageDetails(interestRate, propertyValue, balanceRemaining, mortgageTerm);
        tableData.currencySymbol = currencySymbol;
        totalInterestPayable = tableData.yearlyDetails.at(-1)?.totalInterestPaid;
        monthlyPayment = getMortgagePayment(interestRate, balanceRemaining, mortgageTerm);
        loanToValue = Math.round((balanceRemaining / propertyValue) * 100);
        shareLink = 'https://www.multiplyr.net/mortgage-repayment-calculator?'
            + 'pv='  + propertyValue
            + '&br=' + balanceRemaining
            + '&ir=' + interestRate
            + '&mt=' + mortgageTerm
            + '&cs=' + currencySymbolCode;
    }

    return (
        <div className='results-grid-container'>
        <Grid item sm={12} md={9} sx={{ marginTop: 2, p: 3 }}>
            { tableData.yearlyDetails?.length &&
                <>
                    <Grid container sx={{ marginTop: 3 }}>
                        <Grid item xs={12} sm={9} sx={{ textAlign: 'left' }}>
                            <Typography variant="h5" sx={{ paddingLeft: '16px', paddingBottom: '24px' }}>
                                Monthly mortgage payment: <strong>{ currencySymbol + CommonFunctions.getCommaSeparatedTwoDecimalsNumber(monthlyPayment) }</strong>
                            </Typography>
                            <Typography variant="h6" sx={{ paddingLeft: '16px', paddingBottom: '16px'  }}>
                                Current loan to value percentage: <strong>{loanToValue}%</strong>
                            </Typography>
                            <Typography variant="h6" sx={{ paddingLeft: '16px', paddingBottom: '24px'  }}>
                                Total interest payable over mortgage term: <strong>{ currencySymbol + CommonFunctions.getCommaSeparatedTwoDecimalsNumber(totalInterestPayable)}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }} className='share-button-grid-item'>
                        <Button color="secondary" variant="contained" onClick={handleOpenShareDialog}>
                            Share results
                        </Button>
                        </Grid>
                    </Grid>
                    <Dialog
                        onClose={handleCloseShareDialog}
                        aria-labelledby="customized-dialog-title"
                        open={openShareDialog}>
                        <DialogTitle id="customized-dialog-title">
                            Share link
                        </DialogTitle>
                        <DialogContent dividers>
                        <Typography gutterBottom>
                            Please use this link to share your results:<br />
                            <strong>{shareLink}</strong>
                        </Typography>
                        <br />
                        <Button color="primary" variant="contained" onClick={() => {navigator.clipboard.writeText(shareLink);}}>
                            Copy to clipboard
                        </Button>
                        </DialogContent>
                        <DialogActions>
                        <Button autoFocus onClick={handleCloseShareDialog}>
                            Close
                        </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
            { tableData.yearlyDetails?.length && <MortgageTable {...tableData} /> }
        </Grid>
        </div>
    );
}