import React from 'react';
import { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Button, TextField } from '@material-ui/core';
import { Grid, Typography } from '@mui/material';
import { CurrencyDropDown } from '../CurrencyDropDown';
import { MortgageResults } from './MortgageResults';
import { MortgageTermDropDown } from './MortgageTermDropDown';
import { IMortgageParams } from '../../models/mortgageModels/IMortgageParams';

export default function MortgageForm() {
    const [mortgageParams, setMortgageParams] = useState({} as IMortgageParams);
    const [propertyValueIsValid, setPropertyValueIsValid] = useState(true);
    const [balanceRemainingIsValid, setBalanceRemainingIsValid] = useState(true);
    const [interestRateIsValid, setInterestRateIsValid] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [mortgageTermValue, setMortgageTermValue] = useState('25');
    const [currencySymbolCodeValue, setCurrencySymbolCodeValue] = useState('GBP');

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const form = event.currentTarget
        const formElements = form.elements as typeof form.elements & {
            interestRate: HTMLInputElement,
            propertyValue: HTMLInputElement,
            balanceRemaining: HTMLInputElement,
        }

        setInterestRateIsValid(true);
        setPropertyValueIsValid(true);
        setBalanceRemainingIsValid(true);

        var error = false;
        if (formElements.interestRate.value == null || isNaN(+formElements.interestRate.value) || +formElements.interestRate.value <= 0)
        {
            setInterestRateIsValid(false);
            error = true;
        }
        if (formElements.propertyValue.value == null || +formElements.propertyValue.value <= 0)
        {
            setPropertyValueIsValid(false);
            error = true;
        }
        if (formElements.balanceRemaining.value == null || +formElements.balanceRemaining.value <= 0)
        {
            setBalanceRemainingIsValid(false);
            error = true;
        }
        if (error)
        {
            return;
        }

        setMortgageParams({
            ...mortgageParams,
            currencySymbolCode: currencySymbolCodeValue,
            propertyValue: +formElements.propertyValue.value,
            balanceRemaining: +formElements.balanceRemaining.value,
            interestRate: +formElements.interestRate.value,
            mortgageTerm: +mortgageTermValue,
        });
        
        setSearchParams({
            pv: formElements.propertyValue.value,
            br: formElements.balanceRemaining.value,
            ir: formElements.interestRate.value,
            mt: mortgageTermValue,
            cs: currencySymbolCodeValue,
        });

        return;
    };

    const updateMortgageTerm = (value: string) => {
        setMortgageTermValue(value);
        mortgageTerm = value;
    }

    const updateCurrencySymbolCode = (value: string) => {
        setCurrencySymbolCodeValue(value);
        currencySymbolCode = value;
    }

    const pvSearchParam = searchParams.get("pv");
    const brSearchParam = searchParams.get("br");
    const irSearchParam = searchParams.get("ir");
    const mtSearchParam = searchParams.get("mt");
    const csSearchParam = searchParams.get("cs");
    var propertyValue = null;
    var balanceRemaining = null;
    var interestRate = null;
    var mortgageTerm = '25';
    var currencySymbolCode = 'GBP';

    if (pvSearchParam != null && +pvSearchParam > 0
        && brSearchParam != null && +brSearchParam > 0
        && irSearchParam != null && +irSearchParam > 0
        && mtSearchParam != null && +mtSearchParam > 0
        && csSearchParam != null)
    {
        if(!mortgageParams.currencySymbolCode?.length)
        {
            setMortgageParams({
                ...mortgageParams,
                currencySymbolCode: csSearchParam,
                propertyValue: +pvSearchParam,
                balanceRemaining: +brSearchParam,
                interestRate: +irSearchParam,
                mortgageTerm: +mtSearchParam,
            });
        }

        propertyValue = pvSearchParam;
        balanceRemaining = brSearchParam;
        interestRate = irSearchParam;
        mortgageTerm = mtSearchParam;
        if (mtSearchParam !== mortgageTerm)
        {
            setMortgageTermValue(mtSearchParam);
        }
        currencySymbolCode = csSearchParam;
        if (csSearchParam !== currencySymbolCode)
        {
            setCurrencySymbolCodeValue(csSearchParam);
        }
    }
    else if ((pvSearchParam != null && isNaN(+pvSearchParam)) ||
        (brSearchParam != null && isNaN(+brSearchParam)) ||
        (irSearchParam != null && isNaN(+irSearchParam)) ||
        (mtSearchParam != null && isNaN(+mtSearchParam)) ||
        (csSearchParam != null))
    {
        setSearchParams();
    }

    return (
        <Grid container spacing={2} sx={{ flex: 1, marginLeft: 0, paddingLeft: 0, width: '100%' }}>
            <Grid item sm={12} md={3} sx={{ backgroundColor: '#f3f3f3', marginTop: 2, p: 3 }}>
                <Typography variant="subtitle1" component="h3" sx={{ color:'#555' }}>
                    <b>Enter your mortgage details:</b>
                </Typography>
                <form id="mortgagePaymentForm" onSubmit={handleSubmit}>
                    <CurrencyDropDown
                        defaultValue={currencySymbolCode}
                        onChange={(e) => updateCurrencySymbolCode(e.target.value)} />
                    <TextField
                        error={!propertyValueIsValid}
                        helperText={propertyValueIsValid ? '' : 'Please enter a number greater than 0'}
                        id="propertyValue"
                        className='calculator-text-field'
                        label="Property value *"
                        variant="outlined"
                        defaultValue={propertyValue}
                        type="number" />
                    <TextField
                        error={!balanceRemainingIsValid}
                        helperText={balanceRemainingIsValid ? '' : 'Please enter a number greater than 0'}
                        id="balanceRemaining"
                        className='calculator-text-field'
                        label="Balance remaining *"
                        variant="outlined"
                        defaultValue={balanceRemaining}
                        type="number" />
                    <TextField
                        error={!interestRateIsValid}
                        helperText={interestRateIsValid ? '' : 'Please enter a decimal greater than 0'}
                        id="interestRate"
                        className='calculator-text-field'
                        label="Interest rate (%) *"
                        variant="outlined"
                        defaultValue={interestRate}
                        type="decimal" />
                    <MortgageTermDropDown
                        defaultValue={mortgageTerm}
                        onChange={(e) => updateMortgageTerm(e.target.value)} />
                    <br />
                    <br />
                    <Button id="calculateMortgagePayments" color="primary" variant="contained" type="submit">
                        Calculate
                    </Button>
                </form>
            </Grid>

            { mortgageParams.currencySymbolCode?.length && <MortgageResults {...mortgageParams} /> }
        </Grid>
    );
}