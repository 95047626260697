import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import MortgageRepaymentCalculator from './pages/MortgageRepaymentCalculator';

const Main = () => {
return (         
    <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/mortgage-repayment-calculator' element={<MortgageRepaymentCalculator/>} />
    </Routes>
);
}
export default Main;