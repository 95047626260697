import React from 'react';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './Main';

// https://stackoverflow.com/questions/49535551/change-primary-and-secondary-colors-in-mui
const theme = createTheme({
  palette: {
    primary: {
      main: '#3f72af'
    },
    secondary : {
      /* https://htmlcolorcodes.com/colors/shades-of-orange/ */
      main: '#D27D2D'
    }
  }
});

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <Header />
          <div className="App-main">
            <Main />
          </div>
          <Footer />
        </MuiThemeProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
