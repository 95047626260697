import React from 'react';
import { Link, Typography } from '@mui/material';
import SEO from '../components/SEO';
import MainBanner from '../components/MainBanner';

const Contact = () => {
    return (
        <>
            <SEO
                title='Contact Us | multiplyr.net'
                description="Please use our contact form to get in touch, we'&apos;'d love to hear from you"
                url='https://www.multiplyr.net/contact'
                keywords='mortgage repayment calculator, annual, monthly, interest, interest rate, mortgage, repayment, mortgage repayments, repayments, calculator, mortgage monthly, monthly mortgage repayment, mortgage rate change, mortgage rate, mortgage rate calculator'
                name='multiplyr.net'
                type='website' />
            <MainBanner
                title="Contact"
                subtitle="Please use the email address below to get in touch, we'd love to hear from you" />

            <Typography variant="h6" component="h2" sx={{ m:2, paddingTop: 2 }}>
                Get in touch using <Link href='mailto:info@multiplyr.net'>info@multiplyr.net</Link>
            </Typography>
        </>
    );
}
export default Contact;