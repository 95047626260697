import React from "react";
import { Link } from 'react-router-dom';
import {AppBar, Toolbar} from '@material-ui/core';
import logo from '../assets/multiplyr-logo-blue.svg';

function Header() {
  return (
    <AppBar
      elevation={0} // remove box shadow
      position='static'
      className='App-bar'>
      <Toolbar>
        <Link to='/'>
          <img src={logo} className="multiplyr-logo" alt="multiplyr logo" height={45} />
        </Link>
      </Toolbar>
    </AppBar>
  );
}
export default Header;