// https://stackoverflow.com/questions/52141715/number-value-format-with-comma-and-two-decimal-points
export function getCommaSeparatedTwoDecimalsNumber(number: number | undefined) {
  if (number === undefined)
    return '0';  
  const fixedNumber = number.toFixed(2);
  return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getCurrencySymbols() {
 return [
    {
      value: 'GBP',
      label: '£',
    },
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
  ];
}

export function getCurrencySymbolByCode(currencySymbolCode: string) {
    const currencies = getCurrencySymbols();
    return currencies.find(element => element.value === currencySymbolCode)?.label;
}