import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const years = Array.from({length: 40}, (_, i) => i + 1);

type MortgageTermDropDownProps = {
    defaultValue: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>
};

export class MortgageTermDropDown extends React.Component<MortgageTermDropDownProps> {
    render () {
        return (
            <TextField
                id="MortgageTermDropDown"
                className='calculator-text-field'
                select
                label="Mortgage term (years)"
                onChange={this.props.onChange}
                defaultValue={this.props.defaultValue}
                variant="outlined"
                type="text"
            >
                {years.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
}