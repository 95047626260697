import React from 'react';
import { Tooltip} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

export default function InfoTooltip({ text } : { text: string; }) {
  return (
    <Tooltip title={text} sx={{ height: 15, color: '#999' }}>
      <HelpOutline />
    </Tooltip>
  );
}