import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({title, description, url, keywords, name, type} :
    { title: string; description: string; url: string; keywords: string; name: string; type: string }) {
    return (
        <Helmet>

            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            <link rel="canonical" href={url} />

            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />

            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />

        </Helmet>
    )
}