import React from 'react';
import SEO from '../components/SEO';
import MainBanner from '../components/MainBanner';
import MortgageForm from  '../components/mortgages/MortgageForm';

const MortgageRepaymentCalculator = () => {
    return (
        <>
            <SEO
                title='Mortgage Repayment Calculator - Calculate your monthly payment amount'
                description='Calculate your monthly mortgage payment along with a year-by-year breakdown of interest and balance payments'
                url='https://www.multiplyr.net/mortgage-repayment-calculator'
                keywords='mortgage repayment calculator, annual, monthly, interest, interest rate, mortgage, repayment, mortgage repayments, repayments, calculator, mortgage monthly, monthly mortgage repayment, mortgage rate change, mortgage rate, mortgage rate calculator'
                name='multiplyr.net'
                type='website' />

            <MainBanner
                title="Mortgage Repayment Calculator"
                subtitle="Enter mortgage details below to calculate the monthly mortgage payment and a year-by-year breakdown of interest and balance payments" />
            
            <MortgageForm />
        </>
    );
}
export default MortgageRepaymentCalculator;