import React from "react";
import { Container } from '@material-ui/core';
import { Typography } from "@mui/material";

function MainBanner({ title, subtitle } : { title: string; subtitle: string; }) {
    return (
        <Container
            maxWidth={false}
            disableGutters={true}
            className="App-banner">
            <Container maxWidth="md">
                <Typography variant="h5" component="h1">
                    {title}
                </Typography>
                <br />
                <Typography variant="subtitle1">
                    {subtitle}
                </Typography>
            </Container>
        </Container>
    );
}
export default MainBanner;