import React from "react";
import { Link } from 'react-router-dom';
import { Container, Divider } from '@material-ui/core';
import { Typography } from "@mui/material";

function Footer() {
    return (
        <Container
            maxWidth={false}
            className="App-footer">
            
            <Link to='/'>Home</Link>
            <br />
            <Link to='/mortgage-repayment-calculator'>Mortgage Repayment Calculator</Link>
            <br />
            <Link to='/contact'>Contact</Link>
            <br />
            <br />

            <Divider />
            
            <br />
            <Typography variant="body1">
                &copy; {new Date().getFullYear()} multiplyr.net
            </Typography>
        </Container>
    );
}
export default Footer;