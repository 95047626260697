import React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Typography } from "@mui/material";
import { Button } from '@material-ui/core';
import { IPaperGridItem } from '../models/IPaperGridItem';

const PaperGridItem: React.FC<IPaperGridItem> = (props) => {
    const { title, subtitle, link } = props;
    return (
        <Link to={link}>
            <Paper className='paper-grid-item' sx={{ height:'100%' }} elevation={3}>
                <div className='paper-grid-item-top'>
                    <Typography variant="subtitle1" component="h4" dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <div className='paper-grid-item-bottom'>
                    <Typography variant="body2">
                        {subtitle}
                    </Typography>
                </div>
                <Button color="secondary" variant="contained" className='paper-grid-item-button'>
                    Go to Calculator
                </Button>
            </Paper>
        </Link>
    );
}
export default PaperGridItem;