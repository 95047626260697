import { IMortgageYearDetail } from '../models/mortgageModels/IMortgageYearDetail';

// Calculates a monthly mortgage payment given the rate, balance and term
export function getMortgagePayment (
    interestRate: number,
    startingBalance : number,
    term: number
): number {
    // https://onladder.co.uk/blog/how-to-calculate-mortgage-repayments/
    var monthlyInterestRate = (interestRate / 100) / 12;
    var totalPayments = term * 12;
    var magicNumber = Math.pow(1 + monthlyInterestRate, totalPayments);
    var monthlyPayment =
    startingBalance *
    (
        (monthlyInterestRate * magicNumber)
        / (magicNumber - 1)
    );
    
    return monthlyPayment;
};

// Calculate yearly details for the full term of a mortgage
export function getYearlyMortgageDetails (
    interestRate: number,
    propertyValue: number,
    startingBalance : number,
    term: number
): IMortgageYearDetail[] {
    let mortgageTable: IMortgageYearDetail[] = [];
    let monthlyPayment = getMortgagePayment(interestRate, startingBalance, term);
    var monthlyInterestRate = (interestRate / 100) / 12;
    var currentBalance = startingBalance;
    var totalEquityPaid = 0;
    var totalInterestPaid = 0;

    for (let i = 0; i < term; i++) {
        
        var yearlyEquityPaid = 0;
        var yearlyInterestPaid = 0;

        // cacluate interest/equity payments by month
        for (let j = 0; j < 12; j++) {
            var interestPayment = currentBalance * monthlyInterestRate;
            var equityPayment = monthlyPayment - interestPayment;
            yearlyEquityPaid = yearlyEquityPaid + equityPayment;
            yearlyInterestPaid = yearlyInterestPaid + interestPayment;
            totalEquityPaid = totalEquityPaid + equityPayment;
            totalInterestPaid = totalInterestPaid + interestPayment;
            currentBalance = currentBalance - equityPayment;
        }

        var yearDetail: IMortgageYearDetail = {
            year: i + 1,
            loanToValue: Math.round((currentBalance / propertyValue) * 100),
            balance: +currentBalance.toFixed(2),
            yearlyEquityPaid: +yearlyEquityPaid.toFixed(2),
            yearlyInterestPaid: +yearlyInterestPaid.toFixed(2),
            totalEquityPaid: +totalEquityPaid.toFixed(2),
            totalInterestPaid: +totalInterestPaid.toFixed(2),
        };

        mortgageTable.push(yearDetail);
    }

    return mortgageTable;
}