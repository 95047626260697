import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import InfoTooltip from '../InfoTooltip';
import * as CommonFunctions from '../../functions/Common';
import { IMortgageYearDetails } from '../../models/mortgageModels/IMortgageYearDetails';

export const MortgageTable = ( { yearlyDetails, currencySymbol } : IMortgageYearDetails) => {

  if(!yearlyDetails?.length){
    return(<div></div>);
  }

  return (
    <TableContainer component={Paper} sx={{ overflow: 'auto' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ backgroundColor: 'rgb(210, 125, 45, 0.1)' }}>
            <TableCell align="center" style={{ verticalAlign: 'top' }}>Year</TableCell>
            <TableCell align="center" style={{ verticalAlign: 'top' }}>
              <InfoTooltip text='Total interest payments paid over the year' />
              Interest payments
            </TableCell>
            <TableCell align="right" style={{ verticalAlign: 'top' }}>
              <InfoTooltip text="Total mortgage balance payments paid over the year" />
              Balance payments
            </TableCell>
            <TableCell align="right" style={{ verticalAlign: 'top' }}>
            <InfoTooltip text="Total mortgage interest paid in this year and the preceeding years" />
              Cumulative interest 
              <br />payments
            </TableCell>
            <TableCell align="right" style={{ verticalAlign: 'top' }}>
              <InfoTooltip text="Total mortgage balance payments made in this year and the preceeding years" />
              Total balance 
              <br />payments
            </TableCell>
            <TableCell align="right" style={{ verticalAlign: 'top' }}>Balance</TableCell>
            <TableCell align="center" style={{ verticalAlign: 'top' }}>Loan to value (%)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {yearlyDetails.map((yearDetail) => (
            <TableRow
              key={yearDetail.year}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {yearDetail.year}
              </TableCell>
              <TableCell align="right">
                {currencySymbol + CommonFunctions.getCommaSeparatedTwoDecimalsNumber(yearDetail.yearlyInterestPaid)}
                </TableCell>
              <TableCell align="right">
                {currencySymbol + CommonFunctions.getCommaSeparatedTwoDecimalsNumber(yearDetail.yearlyEquityPaid)}
                </TableCell>
              <TableCell align="right">
                {currencySymbol + CommonFunctions.getCommaSeparatedTwoDecimalsNumber(yearDetail.totalInterestPaid)}
                </TableCell>
              <TableCell align="right">
                {currencySymbol + CommonFunctions.getCommaSeparatedTwoDecimalsNumber(yearDetail.totalEquityPaid)}
                </TableCell>
              <TableCell align="right">
                {currencySymbol + CommonFunctions.getCommaSeparatedTwoDecimalsNumber(yearDetail.balance)}
                </TableCell>
              <TableCell align="center">
                {yearDetail.loanToValue}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}