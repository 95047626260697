import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { getCurrencySymbols } from "../functions/Common";

const currencies = getCurrencySymbols();

type CurrencyDropDownProps = {
    defaultValue: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>
  };

export class CurrencyDropDown extends React.Component<CurrencyDropDownProps> {
    render () {
        return (
            <TextField
                id="currencyDropDown"
                className='calculator-text-field'
                select
                defaultValue={this.props.defaultValue}
                onChange={this.props.onChange}
                label="Currency"
                variant="outlined"
                type="text"
            >
            {currencies.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
            </TextField>
        )
    };
};